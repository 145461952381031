"use client";
//https://nextjs.org/learn/basics/dynamic-routes/dynamic-routes-details
//this currently is the catch all of not found until they update not-found.tsx at the root of app folder
// import { notFound } from "next/navigation";

import Link from "next/link";
export default function Error500() {
  //currently this is triggered only when calling
  // return notFound();
  return (
    <div className="flex w-full flex-col justify-between">
      <section className="flex h-full items-center p-16 ">
        <div className="container mx-auto my-8 flex flex-col items-center justify-center px-5">
          <div className="max-w-md text-center">
            <div
              className={`mb-8 flex w-full flex-row place-items-center  text-9xl font-extrabold`}
            >
              <div className="mx-auto"></div>

              {Array.from("MLTask").map((char, index) => {
                const randomRotation =
                  Math.floor(Math.random() * (240 - 40 + 1)) + 40;

                return (
                  <div
                    key={index}
                    style={{ transform: `rotate(${randomRotation}deg)` }}
                  >
                    <p
                      className={`text-accent ${
                        index % 2 == 0 ? " -my-5 mx-5 " : " -mx-2 my-10 "
                      }`}
                    >
                      {char}
                    </p>
                  </div>
                );
              })}

              <div className="mx-auto"></div>
            </div>
            <p className="text-2xl font-semibold md:text-3xl">
              Oops! Something went wrong.
            </p>
            <p className="mb-8 mt-4 "></p>
            <Link href="/" className="btn-secondary btn">
              Back to AI Tasks
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
